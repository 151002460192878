import React from 'react';
import Layout from "../components/layout";
import styled from "styled-components";
import Helmet from 'react-helmet'

const Application = () => {

    const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
    `

    return (
        <Layout>
            <Helmet>
                <title>Square One | Application</title>
            <meta name="title" content="Square One Application" />
            <meta name="description" content="You're just a step away from your new home!"/>
            <link rel="canonical" href="http://squareone-living.com/application" />
            

          </Helmet>
            <FormContainer>
                    <iframe name="application form" title="application form" src="https://tfaforms.com/4840254" height="100%" width="100%"
                frameborder="0" ></iframe>
                <script src="//tfaforms.com/js/iframe_resize_helper.js"></script>
            </FormContainer>
        
    </Layout>

    )
   

}

export default Application;